//Colors
$dark: #000;
$white: #fff;

//principales
$green-guay: #9cb537;
$pink-guay: #af2f72;
$blue-guay: #2188b4;
$yellow-guay: #dfa444;
$light-blue: #f3f2f8;

//secundarios
$sec-blue-guay: #15253c;
$sec-gray-guay: #5b5b5b;
$sec-blue-gray-guay: #71768d;
$sec-white-gray-guay: #efeff6;
$sec-light-blue-guay: #1F7FA8;
$sec-light-gray-guay: #707070;

//en degradado
$deg-green-guay: #6fbb00;
$deg-blue-guay: #0095c2;
$deg-yellow-guay: #dfa444;
$deg-pink-guay: #ae2f72;
$deg-blue-guay-2: #cbe3eb;

//adicionales
$ad-purple: #5f5fca;
$ad-blue-navy: #00001D;
$ad-grey-guay: #686D82;
$ad-grey-white-guay: #8E8C8D;
$ad-white-grey-guay: #EEEEEE;
$ad-blue-dark-guay: #000000;