// General
* {
    font-family: 'Quicksand', sans-serif;
}
body {
    font-family: 'Quicksand', sans-serif;
}
html {
    min-height: 100%;
    position: relative;
}
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    div, span, input, textarea, select, option {
        // font-size: 0.75rem !important;
        font-size: 0.75rem;

    }
    form span.w-35 {
        width: 100% !important;
    }
    form span.w-25 {
        width: 100% !important;
    }
    form span.w-15 {
        width: 100% !important;
    }
    img.w-50 {
        display: none;
    }
    img.sponsor-img {
        width: 50% !important;
    }
}
// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) { 
    div, span, input, textarea, select, option {
        font-size: 0.75rem !important;
    }
    img.sponsor-img {
        width: 50% !important;
    }
    form span.w-15 {
        width: 100% !important;
    }
}
// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) { 
    div, span, input, textarea, select, option {
        font-size: 0.85rem !important;
    }
    img.sponsor-img {
        width: 75% !important;
    }
}
// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) { 
    div, span, input, textarea, select, option {
        font-size: 0.85rem !important;
    }
    img.sponsor-img {
        width: 75% !important;
    }
}
// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (min-width: 1399.98px) { 
    div, span, input, textarea, select, option {
        font-size: 1rem !important;
    }
    img.sponsor-img {
        width: 75% !important;
    }
}
// Fonts
.fs-7 {
    font-size: 0.85rem;
}
.fs-8 {
    font-size: 0.75rem;
}
.fs-05625 {
    font-size: calc(0.56255em + .5vmin) !important;
}
.fs-0625em {
    font-size: calc(0.625em + .5vmin) !important;
}
.fs-075em {
    font-size: calc(0.75em + .5vmin) !important;
}
.fs-0875em {
    font-size: calc(0.875em + .5vmin) !important;
}
.fs-1em {
    font-size: calc(1em + .5vmin) !important;
}
.fs-1-5em {
    font-size: calc(1.5em + .5vmin) !important;
}
// Text
.text-pink {
    color: $pink-guay;
}
.text-green {
    color: $green-guay;
}
.text-yellow {
    color: $yellow-guay;
}
.text-sec-blue-gray-guay {
    color: $sec-blue-gray-guay;
}
.text-cian {
    color: $blue-guay;
}
.text-cian-hover:hover {
    color: $blue-guay;
}
.text-blue{
    color: $sec-blue-guay;
}
.text-white{
    color: $white;
}
.text-sec-white-gray-guay{
    color: $sec-white-gray-guay;
}
.text-sec-light-blue-guay{
    color: $sec-light-blue-guay;
}
.text-ad-grey-white-guay {
    color: $ad-grey-white-guay;
}
.text-sec-light-gray-guay{
    color: $sec-light-gray-guay;
}
.text-ad-grey-guay{
    color: $ad-grey-guay;
}
.text-ad-blue-dark-guay{
    color: $ad-blue-dark-guay;
}

.text-spacing-1-45{
    letter-spacing: calc(1.45em + .5vmin) !important;
}
// Backgrounds
.bg-image-general {
    background: url("../../../public/assets/img/Proceso_General_back.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bg-image-login {
    background: url("../../../public/assets/img/login_2.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bg-blue-navy {
    background-color: $ad-blue-navy;
}
.bg-blue-guay {
    background-color: $blue-guay;
}
.bg-light-blue {
    background-color: $light-blue;
}
.bg-white {
    background-color: $white;
}
.bg-green-guay {
    background-color: $green-guay;
}
.bg-yellow-guay {
    background-color: $yellow-guay;
}
.bg-pink-guay {
    background-color: $pink-guay;
}
.bg-ad-grey-guay {
    background-color: $ad-grey-guay;
}
.bg-sec-light-gray-guay {
    background-color: $sec-light-gray-guay;
}
// Buttons
.btn-pink-guay {
    background-color: $pink-guay !important;
    border-color: $pink-guay !important;
}
.btn-save-style2:hover {
    background-color:$white;
    border: 1px solid $pink-guay !important;
    color:$pink-guay;
}

.btn-cancel-style2:hover {
    background-color:$white;
    border: 1px solid $sec-blue-guay !important;
    color: $sec-blue-guay;
  }
  
.btn-cancel-style2 {
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
    font-size: inherit;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 35px;
    background-color: $sec-blue-guay;
    color:$white;
}
.btn-save-style2 {
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
    font-size: inherit;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 35px;
    background-color: $pink-guay;
    color: $white;
}
  
// Image
.img-150 {
    width: 150px;
}
.img-250 {
    width: 250px;
}
.img-h30px {
    height: 30px;
}
.img-h50px {
    height: 50px;
}
// Inputs
.textarea-unresize {
    resize: none;
}
.input-icon-right input {
    border-right: none;
}
.input-icon-right span {
    background-color: transparent;
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input-group-guay span {
    border: none;
    background-color: transparent;
}
.input-group-guay input {
    border: 1px solid $blue-guay !important;
    border-radius: 10px !important;
}
.input-group-guay textarea {
    border: 1px solid $blue-guay !important;
    border-radius: 10px !important;
}
.input-group-guay select {
    border: 1px solid $blue-guay !important;
    border-radius: 10px !important;
}
.input-guay-search input {
    border: 1px solid $sec-blue-guay !important;
    border-radius: 30px !important;
    height: 35px !important;
}
.react-dual-listbox select {
    border: 1px solid $blue-guay !important;
    border-radius: 10px !important;
}
.react-dual-listbox select::-webkit-scrollbar {
    -webkit-appearance: none;
}
.react-dual-listbox select::-webkit-scrollbar:vertical {
    width: 7.5px;
}
.react-dual-listbox select::-webkit-scrollbar-button:increment, .select-option-style-1::-webkit-scrollbar-button {
    display: none;
}
.react-dual-listbox select::-webkit-scrollbar-thumb {
    background-color: $sec-blue-gray-guay;
    border: 2px solid $sec-white-gray-guay;
}
.form {
    position: relative;
    width: 100%;
    height: 40px;

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        border-bottom: 1px solid #686D82;
        outline: none;
        padding: 0.5rem;
        background-color: transparent;
        font-size: calc(0.75em + .5vmin);
        // z-index: 10;
        color: #686D82;
        font-family: 'Quicksand';
    }

    &__label {
        position: absolute;
        left: 0rem;
        top: 1rem;
        color: #686D82;
        cursor: text;
        transition: top 200ms ease-in,
        left 200ms ease-in,
        font-size calc(0.5em + .5vmin)  200ms ease-in;
        background-color: transparent;
        font-family: 'Quicksand';
    }

}
.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
    top: -1rem;
    font-size: calc(0.5em + .5vmin);
    left: 0rem;
    color: #1F7FA8;
    font-weight: bold;
}
.form-groups{
    position: relative;
    width: 100%;
    height: 40px;
}
.label-groups{
    position: absolute;
    top: -1rem;
    font-size: calc(0.5em + .5vmin);
    left: 0rem;
    color: #1F7FA8;
    font-weight: bold;
}
.form-check-guay .form-check-input:checked {
    background-color: #15253C;
    border-color: #15253C;;
}
.input-search-icon i {
    position: absolute;
    top: 5px;
    font-size: 20px;
    right: 30px;
    cursor: text;
}
.input-search-img img {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: text;
}
.input-img img {
    position: absolute;
    top: 15px;
    //bottom: 5px;
    right: 5px;
    cursor: pointer;
    // z-index: 100;
}
// Tables
.table > :not(:first-child) {
    border-top: 0px solid currentColor;
}
td {
    border-style: none;
}
th {
    border-style: none;
}
tr {
    border-color: #9cb0d1
}
// Borders
.border-active-guay {
    border: none;
    border-bottom: 3px solid #1F7FA8;
}
// Extras
.pointer {
    cursor: pointer;
}
.w-15 {
    width: 15% !important;
}
.w-35 {
    width: 35% !important;
}
.pull-up {
    transition: all 0.25s ease !important;
}
.pull-up:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
    z-index: 999;
}
.inline-group-1 div.react-datepicker-wrapper {
    width: 75% !important;
}
.rounded-25px {
    border-radius: 25px;
}
.rounded-10px {
    border-radius: 10px;
}
.overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-div-style-1 {
    border: 1px solid gray !important;
    padding: 50px;
    border-radius: 30px;
    background-color: $white;
}

.btn-close-1 {
    position: absolute;
    right: 0;
    margin-top: -20px;
    margin-right: -25px;
}
.selectBorder {
    border: 1px solid #2188b4 !important;
    border-radius: 10px !important;
}
.nav-link-guay{
    display: block;
    padding: 0rem 6rem 0rem 6rem;
    color:  $sec-blue-guay !important;
    font-size: calc(1em + 1vmin) !important;
    font-weight: bold;
}

.border-bottom-light-blue-guay {
    border-bottom: 3px solid $sec-light-blue-guay !important;
}
.border-bottom-red {
    border-bottom: 1px solid red !important;
}
.border-sec-blue-guay {
    border: 3px solid $sec-blue-guay !important;
}

.list-type-none{
    list-style: none;
}

.icon-size-20x20{
    height: 20px !important;
    width: 20px !important;
}

.icon-size-27x27{
    height: 27px !important;
    width: 27px !important;
}

.icon-size-20x18{
    height: 20px !important;
    width: 18px !important;
}

.icon-size-15x15{
    height: 15px !important;
    width: 15px !important;
}

.icon-size-10x10{
    height: 10px !important;
    width: 10px !important;
}

.icon-size-16x15{
    height: 16px !important;
    width: 15px !important;
}

.icon-size-18x18{
    height: 18px !important;
    width: 18px !important;
}

.icon-size-11x10 {
    height: 11px !important;
    width: 10px !important;
}

.icon-input {
    position: absolute;
    top: 15px
}

.icon-size-modal .swal2-icon-content {
    font-size: 4rem !important;
}

.font-size-1em{
    font-size: calc(1em + 1vmin);
}

.font-family-quicksand{
    font-family: Quicksand !important;
}

.dropdown-guay {
    background-color: #fff;
    color: #686D82;
    border-radius: 20px;
    width: 100%;
}

.h-1of3 {
    height: 33.33%;
}

.card-90x120{
    width: 90px !important;
    height: 120px !important;
}
.opacity-20{
    opacity: 20% !important;
}
.circle-shadow{
    box-shadow: '1px -2px 11px #ccc';
    border-radius: '8px';
}
.card-border-magenta{
    border: 1px solid rgba(254, 0, 58, 0.2) !important;
}
.card-border-sec-light-blue-guay{
    border: 1px solid $sec-light-blue-guay !important;
}
.card-border-sec-white-gray-guay{
    border: 3px solid $sec-white-gray-guay !important;
}
.card-border-white{
    border: 1px solid $white !important;
}
.card-bg-idle{
    background-color: $sec-white-gray-guay !important;
    opacity: 55%;
}
div.scrollmenuCards {
    overflow: auto;
}
.scrollmenuCards::-webkit-scrollbar {
    -webkit-appearance: none;
}
.scrollmenuCards::-webkit-scrollbar:horizontal {
    width: 3px;
}
.scrollmenuCards::-webkit-scrollbar-button:increment, .scrollmenuCards::-webkit-scrollbar-button {
    display: none;
}
.scrollmenuCards::-webkit-scrollbar-thumb {
    background-color: $sec-white-gray-guay;
    border-radius: 20px;
    border: 2px solid  $white;
}
.scrollmenuCards::-webkit-scrollbar-track {
    border-radius: 10px;
}
.my-actions-alert { margin: 0 2em; }
.order-1-alert { order: 1; }
.order-2-alert { order: 2; }
.order-3-alert { order: 3; }

.right-gap-alert {
  margin-right: auto;
}
.sticky-filters {
    position: sticky;
    overflow: hidden;
    top: 110px;
    z-index: 1001;
    background-color: #fff;
}
.sticky-panel {
    position: sticky;
    z-index: 1000;
    top: 41px;
}
.sticky-guay {
    position: sticky;
    top: 0;
}
.pill-span {
    -webkit-box-shadow: 0px 0px 50px -15px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 50px -15px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 50px -15px rgba(0,0,0,0.5);
    border-radius: 25px;
}
.w-auto {
    width: auto !important;
}
.graphicTitle {
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    font-weight: bold !important;
}