
.card-drag-drop-plans {
    -webkit-box-shadow: 0px 0px 50px -15px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 50px -15px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 50px -15px rgba(0,0,0,0.5);
    border-radius: 25px;
    min-height: 300px;
    max-height: 400px;
    overflow: auto;
}

.card-drag-drop-plans::-webkit-scrollbar {
    -webkit-appearance: none;
}
  
.card-drag-drop-plans::-webkit-scrollbar:vertical {
    width: 10px;
}
  
.card-drag-drop-plans::-webkit-scrollbar-button:increment, .card-drag-drop-plans::-webkit-scrollbar-button {
    display: block;
}
  
.card-drag-drop-plans::-webkit-scrollbar:horizontal {
    height: 10px;
}
  
.card-drag-drop-plans::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
  
.card-drag-drop-plans::-webkit-scrollbar-track {
    border-radius: 10px;
}

.card-drag-drop-plans .item {
    border-radius: 20px;
}

.item-borde-blue {
    border: 1px solid $sec-light-blue-guay;
}