.login__container-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login__bg-blue-navy {
    background-color: $ad-blue-navy;
}

.login__form-login {
    color: #fff !important;
}

.login__form-login:focus {
    color: #000 !important;
}

.login__form-floating > label {
    color: $ad-purple;
}