.generic__show-main-container {
    position: relative;
    animation-name: showMainContainer;
}
.generic__show-main-container.container-1 {
    animation-duration: 1s;
}
@keyframes showMainContainer {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.generic__show-container {
    position: relative;
    animation-name: showContainer;
}
.generic__show-container.left {
    position: relative;
    animation-name: showContainerLeft;
}
.generic__show-container.rigth {
    position: relative;
    animation-name: showContainerRight;
}
.generic__show-container.top {
    position: relative;
    animation-name: showContainerTop;
}
.generic__show-container.container-1 {
    animation-duration: .5s;
}
.generic__show-container.container-2 {
    animation-duration: 1.25s;
}
.generic__show-container.container-3 {
    animation-duration: 2s;
}
@keyframes showContainer {
    0% {
        left: 0px;
        top: 0px;
        opacity: 0;
    }
    50% {
        left: 0px;
        top: 100px;
        opacity: 0;
    }
    100% {
        left: 0px;
        top: 0px;
        opacity: 1;
    }
}
@keyframes showContainerLeft {
    0% {
        top: 0px;
        left: 0px;
        opacity: 0;
    }
    50% {
        top: 0px;
        left: 100px;
        opacity: 0;
    }
    100% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
}
@keyframes showContainerRight {
    0% {
        top: 0px;
        right: 0px;
        opacity: 0;
    }
    50% {
        top: 0px;
        right: 100px;
        opacity: 0;
    }
    100% {
        top: 0px;
        right: 0px;
        opacity: 1;
    }
}
@keyframes showContainerTop {
    0% {
        left: 0px;
        bottom: 0px;
        opacity: 0;
    }
    50% {
        left: 0px;
        bottom: 100px;
        opacity: 0;
    }
    100% {
        left: 0px;
        bottom: 0px;
        opacity: 1;
    }
}
.jumping:hover {
    position: relative;
    animation-name: shake;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        -ms-transform: rotate(0deg); 
        transform: rotate(0deg);
    }
    25% {
        -ms-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    50% {
        -ms-transform: rotate(-10deg); 
        transform: rotate(-10deg);
    }
    75% {
        -ms-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    100% {
        -ms-transform: rotate(0deg); 
        transform: rotate(0deg);
    }
}

.jumping-2 {
    position: relative;
    animation-name: shake_2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes shake_2 {
    0% {
        -ms-transform: rotate(0deg); 
        transform: rotate(0deg);
    }
    25% {
        -ms-transform: rotate(50deg);
        transform: rotate(50deg);
    }
    50% {
        -ms-transform: rotate(-50deg); 
        transform: rotate(-50deg);
    }
    75% {
        -ms-transform: rotate(50deg);
        transform: rotate(50deg);
    }
    100% {
        -ms-transform: rotate(0deg); 
        transform: rotate(0deg);
    }
}