.header__box-shadow {
    box-shadow: 1px 1px 25px 1px $dark;
}
.div-notifications {
    width: 300px;
    max-height: 400px;
    overflow: auto;
}
.div-notifications::-webkit-scrollbar {
    -webkit-appearance: none;
}
.div-notifications::-webkit-scrollbar:vertical {
    width: 10px;
}
.div-notifications::-webkit-scrollbar-button:increment, 
.div-notifications::-webkit-scrollbar-button {
    display: none;
}
.div-notifications::-webkit-scrollbar:horizontal {
    height: 10px;
}
.div-notifications::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
.div-notifications::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f2f3;
}
.notification-tab:hover {
    background-color: $deg-blue-guay-2;
}
