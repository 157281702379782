.menu__menu-option {
  padding: 0.5rem;
  color: $sec-light-gray-guay;
  transition: 0.5s;
  cursor: pointer;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: calc(0.875em + .5vmin) !important;
  font-family: 'Quicksand' !important;
}
.menu__menu-option span.show-1,
.menu__menu-option span.show-2,
.menu__menu-option span.show-3,
.menu__menu-option span.show-4,
.menu__menu-option span.show-5,
.menu__menu-option span.show-6,
.menu__menu-option span.show-7,
.menu__menu-option span.show-8,
.menu__menu-option span.show-9,
.menu__menu-option span.show-10
.menu__menu-option span.show-11 {
  position: relative;
  animation-name: showOptions;
}
.menu__menu-option span.show-1 {
  animation-duration: .5s;
}
.menu__menu-option span.show-2 {
  animation-duration: .75s;
}
.menu__menu-option span.show-3 {
  animation-duration: 1s;
}
.menu__menu-option span.show-4 {
  animation-duration: 1.25s;
}
.menu__menu-option span.show-5 {
  animation-duration: 1.5s;
}
.menu__menu-option span.show-6 {
  animation-duration: 1.75s;
}
.menu__menu-option span.show-7 {
  animation-duration: 2s;
}
.menu__menu-option span.show-8 {
  animation-duration: 2.5s;
}
.menu__menu-option span.show-9 {
  animation-duration: 2.75s;
}
.menu__menu-option span.show-10 {
  animation-duration: 3s;
}
.menu__menu-option span.show-11 {
  animation-duration: 3.5s;
}
.menu__menu-option:hover {
  padding-left: 1.5rem !important;
  box-shadow: 5px 10px 8px 10px #888888;
  color: $sec-light-gray-guay;
}
@keyframes showOptions {
  0% {
    left: 0px;
    top: 0px;
    opacity: 0;
  }
  50% {
    left: 25px;
    top: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
  }
}
.menu__menu-option-selected {
  background-color: $ad-white-grey-guay;
  border-right: 5px solid $green-guay;
  color: $sec-light-gray-guay;
}
